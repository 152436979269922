//import Module
import { React, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import albumArray from "../data/album.json";

//import Style
import "../styles/discography.css";

function AlbumArticle(props) {
  const { t } = useTranslation();

  return (
    <div className="discoAlbum" id="discoAlbums">
      <div className="discoImage">
        <img src={props.imgSrc} oncontextmenu="return false;" />
      </div>
      <div className="discoText">
        <div className="discoDetail">
          <div>
            <span className="discoSmalls">{t("released")}</span>
            <span>{props.albumRelease}</span>
          </div>
        </div>
        <div className="discoTitle">
          <span>{props.albumTitle}</span>
          <span className="discoLocales">{props.albumLocales}</span>
        </div>
      </div>
    </div>
  );
}

function Discography() {
  const { t } = useTranslation();

  useEffect(() => {
    const titleElement = document.getElementsByTagName("title")[0];
    titleElement.innerHTML = `SNOLAKE : Discography`;
  }, []);

  const albumList = albumArray.albums.map((albums, index) => {
    return (
      <Link to={`/lyrics/${albums.url}`}>
        <AlbumArticle
          imgSrc={albums.img}
          albumRelease={albums.date}
          albumTitle={albums.title}
          albumLocales={t(`${albums.url}`)}
          albumArtists={albums.artist}
        />
      </Link>
    );
  });

  return <div className="discography">{albumList}</div>;
}

export default Discography;
