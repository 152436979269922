//import Module
import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoPeopleCircleSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

//import Style
import "../styles/headfoot.css";

const side = document.getElementsByClassName("sidemenu");

function SideMenu() {
  const { t } = useTranslation();

  function SideOff() {
    side[0].classList.remove("openMenu");
  }

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="sidemenu">
      <div>
        <ul>
          <li className="hide" onClick={SideOff}>
            X
          </li>
          <li>
            <Link to="/discography">{t("discography")}</Link>
          </li>
          <li>
            <a href="https://sekainoowari.jp" target="blank">
              <img
                src={process.env.PUBLIC_URL + "/sekaowa_logo.webp"}
                style={{ filter: "invert(100%)" }}
                alt="SEKAI NO OWARI OFFICIAL SITE"
              />
            </a>
          </li>
          <button onClick={() => changeLanguage("en-US")}>English</button>
          <button onClick={() => changeLanguage("ko-KR")}>한국어</button>
          <button onClick={() => changeLanguage("ja-JP")}>日本語</button>
        </ul>
      </div>
    </div>
  );
}

function Header() {
  const { t } = useTranslation();

  function SideOn() {
    side[0].classList.add("openMenu");
  }

  return (
    <header className="App-header">
      <div className="hamburger" onClick={SideOn}>
        <GiHamburgerMenu />
      </div>
      <div style={{ fontWeight: 900 }}>
        <Link to="/">{t("logo")}</Link>
      </div>
      <div>
        <Link to="/login">
          <IoPeopleCircleSharp />
        </Link>
      </div>
      <div className="backdrop" />
      <SideMenu />
    </header>
  );
}

export default Header;
