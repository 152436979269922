import React from "react";
import { useEffect, useState } from "react";
import { pb } from "../lib/pocketbase";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

function Register() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const isLoggedIn = pb.authStore.isValid;
  useEffect(() => {
    if (pb.authStore.isValid) navigate("/");
  }, [navigate]);

  async function signup(data) {
    console.log(data);
    setLoading(true);
    try {
      const record = await pb.collection("users").create({
        email: data.email,
        password: data.password,
        passwordConfirm: data.password,
      });
      if (record) navigate("/");
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  return (
    <div className="signForm">
      <h1 className="signTitle">회원가입</h1>
      {!isLoggedIn && (
        <form className="inputSection" onSubmit={handleSubmit(signup)}>
          <input
            className="border rounded-md p-1"
            type="text"
            placeholder="input email"
            {...register("email", {
              required: "email is required",
            })}
          />
          <p>{errors.email?.message}</p>
          <input
            className="border rounded-md p-1"
            type="password"
            placeholder="input password"
            {...register("password", {
              required: "password is required",
              minLength: { value: 5, message: "minimum 5" },
            })}
          />
          <p>{errors.password?.message}</p>
          <button
            className="border rounded-md bg-gray-400 py-1 px-4"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "Loading" : "Signup"}
          </button>
        </form>
      )}
    </div>
  );
}

export default Register;
