//import Module
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";

//import Style
import '../styles/home.css'

function Topic(){

    const [info, setInfo] = useState([]);

    const { t } = useTranslation();
   
    useEffect(() => {
        axios.get('https://sekainoowari.jp/wp-json/wp/v2/info')
        .then(response => {
            setInfo(response.data)
        })
        .catch((error) => console.log(error))
    }, [])

    return(
        <div className="topicHome">
            <div className="topicLeft">
                <div className="topictitle">
                    INFO
                </div>
                <div className="topicArticles">
                    {info.map(info => {
                        return(
                        <a href={info.link} target="blank" >
                            <div className="infos" key={info.id}>
                                <span style={{fontWeight:"normal", fontSize:"17px"}}>
                                    {t(`topicId_${info.id}`)}
                                </span>
                                <span style={{fontWeight:400, color:"gray", fontSize:"12px"}}>
                                    {info.date.slice(0,10)}
                                </span>
                            </div>
                        </a>
                        )
                    })}
                </div>
            </div>
            <div className="topicRight">
                <div className="topictitle">
                    NOTICE
                </div>
                <div className="topicArticles">
                    <div className="infos">
                        <span>{t("notice_optimization")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topic