//import Module
import { React, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Newest, NewBanner } from "./newest";
import Topic from "./topic";

//import Style
import "../styles/home.css";

import playlistArray from "../data/playlist.json";

function OfficialPlaylist() {
  const { t } = useTranslation();

  const ofcPlaylist = playlistArray.playlist.map((playlist, index) => {
    return (
      <div key={index}>
        <span>{t(`${playlist.name}`)}</span>
        <ul>
          {playlist.songs.map((song, index) => {
            return (
              <li key={index}>
                <span>{song}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  });

  return (
    <div>
      <span>{t("LakePlaylist")}</span>
      {ofcPlaylist}
    </div>
  );
}

function Home() {
  useEffect(() => {
    const titleElement = document.getElementsByTagName("title")[0];
    titleElement.innerHTML = `SNOLAKE`;
  }, []);

  return (
    <div className="home">
      <NewBanner />
      <Newest />
      <Topic />
      <OfficialPlaylist />
    </div>
  );
}

export default Home;
