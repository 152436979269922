import { React, useEffect } from "react";
import Youtube from "react-youtube";

import albumArray from "../data/album.json";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../styles/lyrics.css";

function Lyrics() {
  const { t } = useTranslation();

  const { albumUrl } = useParams();
  const albumContent = albumArray.albums.filter(
    (content) => content.url === albumUrl
  );

  useEffect(() => {
    const titleElement = document.getElementsByTagName("title")[0];
    titleElement.innerHTML = `SNOLAKE : Lyrics - ${albumContent[0].title}`;
  }, []);

  return (
    <div className="lyricsHome">
      {albumContent.map((content) => (
        <div className="lyricsContainer">
          <div className="lyricsContent">
            <div
              className="lyricsLeft"
              style={{
                backgroundImage: `url(${content.img})`,
                backgroundSize: "cover",
              }}
            />
            <div className="lyricsVideos">
              {content.mvUrl.map((url, index) => {
                const opts = {
                  width: "100%",
                  height: "320",
                  playerVars: {
                    autoplay: 0,
                    rel: 0,
                    modestbranding: 1,
                  },
                };

                const style = {
                  overflow: "hidden",
                  zIndex: 2,
                  borderRadius: "30px",
                  display: "block",
                  bottom: 0,
                  height: "320px",
                  width: "100%",
                };
                return (
                  <div className="videos">
                    <Youtube videoId={url} opts={opts} style={style} />
                    <span className="video_caption">
                      {t("providedBySekaowa")}
                    </span>
                  </div>
                );
              })}
              {content.lakeUrl.map((url, index) => {
                const opts = {
                  width: "100%",
                  height: "320",
                  playerVars: {
                    autoplay: 0,
                    rel: 0,
                    modestbranding: 1,
                  },
                };
                const style = {
                  overflow: "hidden",
                  zIndex: 2,
                  borderRadius: "30px",
                  display: "block",
                  bottom: 0,
                  height: "320px",
                  width: "100%",
                };
                return (
                  <div className="videos">
                    <Youtube videoId={url} opts={opts} style={style} />
                    <span className="video_caption">{t("providedByLake")}</span>
                  </div>
                );
              })}
            </div>
            <div className="lyrics">
              <span>{content.lyrics}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Lyrics;
