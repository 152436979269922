import { useState } from "react";
import { pb } from "../lib/pocketbase";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import "../styles/sign.css";

function Login(props) {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [isLoading, setLoading] = useState(false);
  const [dummy, setDummy] = useState(0);

  const isLoggedIn = pb.authStore.isValid;

  async function login(data) {
    console.log(data);
    setLoading(true);
    try {
      const authData = await pb
        .collection("users")
        .authWithPassword(data.email, data.password);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  function logout() {
    pb.authStore.clear();
    setDummy(Math.random());
  }

  return (
    <div className="signContainer">
      <div className="signForm">
        <h1 className="signTitle">로그인</h1>
        <p className="">
          {isLoggedIn
            ? navigate("/profile")
            : "로그인 후 더 많은 세상을 누려보세요!"}
        </p>
        {isLoggedIn && (
          <button className="" onClick={logout}>
            Logout
          </button>
        )}

        {!isLoggedIn && (
          <form className="inputSection" onSubmit={handleSubmit(login)}>
            <input
              className="border rounded-md p-1"
              type="text"
              placeholder="input email"
              {...register("email")}
            />
            <input
              className="border rounded-md p-1"
              type="password"
              placeholder="input password"
              {...register("password")}
            />
            <button
              className="border rounded-md bg-gray-400 py-1 px-4"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Loading" : "Login"}
            </button>
            <button
              className="border rounded-md bg-gray-400 py-1 px-4"
              type="submit"
              disabled={isLoading}
            >
              <Link to={"/signup"}>Sign Up</Link>
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default Login;
